<template>
    <div>
        
    </div>
</template>

<script>
import { fr } from "../firebase";
export default {
    name: "solicitudFactura",
    data(){
        return{
            paypal_id: "",
            newDoc: [],
            docData: [],

            


        }
    },
    methods: {

        async obtenerDatos(){
            const id = this.$route.query.locatedid_config_set_param;
            const pagosRef = fr.collection('relacionPagos');
            const snapshot = await pagosRef.where('paypal_id', '==', id).get();
            //IF THE DOCUMENT IS NOT FOUND
            if (snapshot.empty) {
                console.log('No matching documents.');
                return;
            }
            snapshot.forEach(doc => {
                this.newDoc.id = doc.id;
                this.docData = doc.data();
            });

            if(this.docData.solicitudEstado != 0){
                await this.$swal({
                icon: "success",
                title: "Tu factura ya ha sido generada",
                text: "Tu factura estará en el correo electrónico proporcionado en un lapso de 24 horas",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showCancelButton: false,
                confirmButtonText: 'Ok',
            }).then((result) => {
                if (result.isConfirmed) {
                console.log('confirmo')
                this.$router.push({ name: "inicio" });
                } else if (result.isDenied) {
                console.log('no confirmo')
                }
            });
            return
            }

            await fr.collection('relacionPagos').doc(this.newDoc.id).update({
                solicitudEstado : 1,
            });

            await fr
                .collection("mail")
                .add({
                    to: "contabilidad@electoraltech.com.mx",
                    template: {
                        name: "avisoContabilidadFactura",
                        data: {
                            rfc: this.docData.datosFacturacion.rfc,
                            nombre: this.docData.datosFacturacion.nombre,
                            estado: this.docData.datosFacturacion.estado,
                            ciudad: this.docData.datosFacturacion.ciudad,
                            calle: this.docData.datosFacturacion.calle,
                            colonia: this.docData.datosFacturacion.colonia,
                            codigoPostal: this.docData.datosFacturacion.codigoPostal,
                            correo: this.docData.datosFacturacion.correoFactura,
                            telefono: this.docData.datosFacturacion.telefono,
                            monto: this.docData.monto,
                            paypal_id: this.docData.paypal_id,
                        }
                    },
                });

            await this.$swal({
                icon: "success",
                title: "Se ha generado tu solicitud correctamente",
                text: "Tu factura estará en tu correo electrónico proporcionado en un lapso de 24 horas",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showCancelButton: false,
                confirmButtonText: 'Ok',
            }).then((result) => {
                if (result.isConfirmed) {
                console.log('confirmo')
                this.$router.push({ name: "inicio" });
                } else if (result.isDenied) {
                console.log('no confirmo')
                }
            });
        }
        
    },

    mounted() {
        this.obtenerDatos();
    }
    
}
</script>

